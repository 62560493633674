function isDevelopment() {
  return window.location.href.indexOf("localhost") >= 0;
}

const developmentConfig = {
  url: {
    login: "http://login.localhost",
    panel: "http://panel.localhost",
    test: "http://test.localhost",
    api: "http://api.localhost",
  },
};

const host = "descubrasuapersonalidade.com.br";

const productionConfig = {
  url: {
    login: `https://login.${host}`,
    panel: `https://panel.${host}`,
    test: `https://test.${host}`,
    api: `https://api.${host}`,
  },
};

export default isDevelopment() ? developmentConfig : productionConfig;
