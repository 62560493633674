import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import { ToastProvider } from "react-toast-notifications";

import "./style.sass";

ReactDOM.render(
  <ToastProvider>
    <Routes />
  </ToastProvider>,
  document.getElementById("root")
);
