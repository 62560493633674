import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { useToasts } from "react-toast-notifications";

import api from "../../util/api";
import "./style.sass";

export default function RecoveryCode() {
  const [code, setCode] = useState("");
  const [next, setNext] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    const email = sessionStorage.getItem("email");
    if (!email) {
      window.location.replace("/password/recovery");
    }
  }, []);

  function handleFormSubmit(event) {
    event.preventDefault();

    api
      .post("/confirm/code", {
        email: sessionStorage.getItem("email"),
        code,
      })
      .then((res) => {
        sessionStorage.setItem("code", code);
        setNext(true);
      })
      .catch((err) => {
        console.warn(err.response && err.response.data);
        addToast("Código inválido", { appearance: "error", autoDismiss: true });
      });
  }

  return next ? (
    <Redirect to="/password/change" />
  ) : (
    <div className="recovery-code">
      <h1>Esqueceu sua Senha</h1>
      <p>Entre com o código recebido para alterar sua senha</p>
      <div className="form-wrapper">
        <form onSubmit={handleFormSubmit}>
          <input
            required
            type="text"
            minLength="6"
            maxLength="6"
            placeholder="Código"
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase())}
          />
          <input type="submit" value="Confirmar" />
        </form>
        <Link to="/" className="back-button-wrapper">
          <MdArrowBack size={20} color="#4F74F9" />
          <span>Voltar para login</span>
        </Link>
      </div>
    </div>
  );
}
