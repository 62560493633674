import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import PasswordRecovery from "./pages/PasswordRecovery";
import RecoveryCode from "./pages/RecoveryCode";
import PasswordChange from "./pages/PasswordChange";

export default function Routes() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/password/recovery" component={PasswordRecovery} />
        <Route path="/recovery/code" component={RecoveryCode} />
        <Route path="/recovery/code" component={RecoveryCode} />
        <Route path="/password/change" component={PasswordChange} />
      </Switch>
    </BrowserRouter>
  );
}
