/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { useToasts } from "react-toast-notifications";

import api from "../../util/api";
import "./style.sass";

export default function PasswordChange() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { addToast } = useToasts();

  useEffect(() => {
    const email = sessionStorage.getItem("email");
    const code = sessionStorage.getItem("code");

    if (!email || !code) {
      window.location.replace("/password/recovery");
    }
  }, []);

  function handleFormSubmit(event) {
    event.preventDefault();
    if (password !== passwordConfirm) {
      addToast("Senhas não conferem", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    api
      .patch("/password/recovery", {
        email: sessionStorage.getItem("email"),
        code: sessionStorage.getItem("code"),
        password,
      })
      .then((res) => {
        addToast("Senha alterada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast("Falha ao alterar senha. Tente novamente.", {
          appearance: "error",
          autoDismiss: true,
        });
      })
      .finally(() => {
        setTimeout(() => {
          window.location.replace("/");
        }, 3000);
      });
  }

  function handlePassword(event) {
    setPassword(event.target.value.replace(/\s/g, ""));
  }

  function handlePasswordConfirm(event) {
    setPasswordConfirm(event.target.value.replace(/\s/g, ""));
  }

  return (
    <div className="password-change">
      <h1>Alterar Senha</h1>
      <div className="form-wrapper">
        <form onSubmit={handleFormSubmit}>
          <input
            required
            type="password"
            placeholder="Senha"
            value={password}
            minLength={8}
            maxLength={64}
            onChange={handlePassword}
          />
          <input
            required
            type="password"
            placeholder="Confirme sua Senha"
            value={passwordConfirm}
            minLength={8}
            maxLength={64}
            onChange={handlePasswordConfirm}
          />
          <input type="submit" value="Alterar" />
        </form>
        <Link to="/" className="back-button-wrapper">
          <MdArrowBack size={20} color="#4F74F9" />
          <span>Voltar para login</span>
        </Link>
      </div>
    </div>
  );
}
