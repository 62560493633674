import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import api from "../../util/api";
import { useToasts } from "react-toast-notifications";

import "./style.sass";

export default function PasswordRecovery() {
  const [email, setEmail] = useState("");
  const [next, setNext] = useState(false);
  const { addToast } = useToasts();

  function handleFormSubmit(event) {
    event.preventDefault();
    api
      .post("/password/recovery", { email })
      .then((res) => {
        sessionStorage.setItem("email", email);
        setNext(true);
      })
      .catch((err) => {
        console.warn(err.response && err.response.data);
        addToast("Email inválido", { appearance: "error", autoDismiss: true });
      });
  }

  return next ? (
    <Redirect to="/recovery/code" />
  ) : (
    <div className="password-recovery">
      <h1>Esqueceu sua Senha</h1>
      <p>
        Entre com o email cadastrado e lhe enviaremos um código para cadastrar
        uma nova senha
      </p>
      <div className="form-wrapper">
        <form onSubmit={handleFormSubmit}>
          <input
            required
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input type="submit" value="Enviar" />
        </form>
        <Link to="/" className="back-button-wrapper">
          <MdArrowBack size={20} color="#4F74F9" />
          <span>Voltar para login</span>
        </Link>
      </div>
    </div>
  );
}
